import { createContext, useEffect, useState } from 'react';

const MediaQueryContext = createContext();

const Breakpoints = {
  SMALL: '490px',
};

const MediaQueryProvider = ({ children }) => {
  const [value, setValue] = useState({
    isSmallScreen: !window.matchMedia(`(min-width: ${Breakpoints.SMALL})`).matches,
  });

  useEffect(() => {
    let timeoutId;

    const handleResize = () => {
      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setValue({
          ...value,
          isSmallScreen: !window.matchMedia(`(min-width: ${Breakpoints.SMALL})`).matches,
        });
      }, 200);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      clearTimeout(timeoutId);
    };
  });

  return <MediaQueryContext.Provider value={value}>{children}</MediaQueryContext.Provider>;
};

MediaQueryProvider.propTypes = {
  children: PropTypes.node,
};

export { MediaQueryContext, MediaQueryProvider };
