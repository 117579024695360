/* eslint-disable import/extensions */
import firebase from 'firebase/app';
// Must be last
import 'firebase/auth';
import 'firebase/firestore';
/* eslint-enable import/extensions */

firebase.initializeApp(FIREBASE_CONFIG);

if (window.location.hostname === 'localhost') {
  firebase.auth().useEmulator('http://localhost:9099/');
  firebase.firestore().useEmulator('localhost', 8080);
} else {
  firebase.firestore().enablePersistence();
}

export default firebase;
